import React, { Component } from 'react';
import { Col, Row, Navbar, Container } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons'
import Logo from '../images/LOGO_SOLUSPOTS_FOOTER.svg';
import VisuelBandeau from '../images/visuel-bandeau.png';
import BetonBackground from '../images/home/beton-background.png';
import BadgeAP from '../images/app-store.svg';
import BadgeGP from '../images/google-play.svg';
import { Capacitor } from '@capacitor/core';

const Footer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentUrl = location.pathname;

    const redirectWithParams = (id, name = false) => {
        navigate('/resultat', {state: { dataId: id, categoryProduct: name }});
    }

    return (
        <>
            {Capacitor.getPlatform() == 'web' && currentUrl != '/connexion' && currentUrl != '/connexion/' &&
            currentUrl != '/profil' && currentUrl != '/profil/' ?
                <div className="bigcontainer">
                    <div className="footer-section-application" style={{ backgroundImage: `url(` + BetonBackground + `)` }}>
                        <Container>
                            <Row className="footer-section-application-content">
                                <Col xs={12} lg={6} className="my-auto">
                                    <img src={VisuelBandeau} alt="Téléchargez l'application - SoluSpots" />
                                </Col>
                                <Col xs={12} lg={6} className="my-auto py-3">
                                    <h2 className="footer-section-application-title">Téléchargez l'appli SoluSpots directement sur votre <span>mobile</span></h2>
                                    <p className="footer-section-application-text">Simplifiez-vous la vie ! Télécharger notre application mobile pour gérer votre flotte et vos réservations où que vous soyez.</p>
                                    <div className="footer-section-application-buttons">
                                        <a href="https://apps.apple.com/fr/app/soluspots/id6451194777" target="_blank"><img className="footer-section-application-buttons-badge" src={BadgeAP} alt="Applisation iOS - SoluSpots - App Store" /></a>
                                        <a href="https://play.google.com/store/apps/details?id=com.soluspot.com" target="_blank"><img className="footer-section-application-buttons-badge" src={BadgeGP} alt="Applisation Android - SoluSpots - Google Play" /></a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                : ''}

            <Navbar className='app-footer'>
                <Container>
                    <div className='app-footer-content'>
                        <Row>
                            <Col xs={12} md={6} xl={6}>
                                <img className='app-footer-content-img' src={Logo} alt="SoluSpot" />
                                <p className='app-footer-content-text'>SoluSpots simplifie la location de véhicule et la gestion de chantier. La plateforme offre une expérience globale fluide, pratique et efficace pour tous les acteurs impliqués dans le transport de béton sur les chantiers de construction.</p>
                            </Col>
                            <Col xs={12} md={6} xl={3}>
                                <p className='app-footer-content-title'>Plan de site</p>
                                <hr />
                                <ul>
                                    <li><Link to="/">Accueil</Link></li>
                                    <li><Link to="/transporteur">Vous êtes transporteur</Link></li>
                                    <li><Link to="/betonnier">Vous êtes bétonnier</Link></li>
                                    {/* <li><Link to="/SoluSpotsPage">Découvrez SoluSpots</Link></li> */}
                                    <li><Link to="/contact">Contact</Link></li>
                                    <li><Link to="/conditions-generales-de-vente">CGV</Link></li>
                                    <li><Link to="/conditions-generales-utilisation">CGU</Link></li>
                                </ul>
                            </Col>
                            <Col xs={12} md={6} xl={3}>
                                <p className='app-footer-content-title'>Rejoignez la communauté SoluSpots</p>
                                <hr />
                                <a className='app-footer-content-social' href="https://www.facebook.com/profile.php?id=100092581599269" target="_blank"><FontAwesomeIcon icon={faFacebookF} /></a>
                                <a className='app-footer-content-social' href="https://www.linkedin.com/company/soluspots/" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                <a className='app-footer-content-social' href="https://www.youtube.com/@soluspots" target='blank'><FontAwesomeIcon icon={faYoutube} /></a>
                                {Capacitor.getPlatform() == 'web' ?
                                    <>
                                        <p className='app-footer-content-text-bold'>Téléchargez dès maintenant l’appli SoluSpots pour une expérience de location de véhicules optimale.</p>
                                        <div className="app-footer-content-buttons">
                                            <a href="https://apps.apple.com/fr/app/soluspots/id6451194777" target="_blank"><img className="app-footer-content-buttons-badge" src={BadgeAP} alt="AppStore" /></a>
                                            <a href="https://play.google.com/store/apps/details?id=com.soluspot.com" target="_blank"><img className="app-footer-content-buttons-badge" src={BadgeGP} alt="Google Play" /></a>
                                        </div>
                                    </>
                                : ''
                                }
                            </Col>
                        </Row>
                    </div>
                    <div className='app-footer-bottom'>
                        <p className='color-white'>Copyright 2024 - All right reserved - <Link className='app-footer-bottom-link' to="/mentions-legales">Mentions légales</Link> - <Link className='app-footer-bottom-link' to="/politique-de-confidentialite">Politique de confidentialité</Link></p>
                    </div>
                </Container>
            </Navbar>
        </>
    );
}

export default Footer;
