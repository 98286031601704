import React,  { Component } from 'react';
import axios from 'axios';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { Capacitor } from '@capacitor/core';
import MetaTags from 'react-meta-tags';

class LoginForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorLogin: "",
            showPassword: false,
        };

        this.submitForm = this.submitForm.bind(this);
    }

    submitForm (values, action) {
        axios.post(process.env.REACT_APP_API_URL + '/v1/login', values)
        .then((response) => {
            if (response.data && response.data.status === 'success') {
                this.props.logIn({
                    token: response.data.content.access_token,
                    firstname: response.data.content.firstname,
                    lastname: response.data.content.lastname,
                    role: response.data.content.role,
                    enabled: response.data.content.enabled,
                    company_name: response.data.company_name,
                    company_key: response.data.company_key,
                    _id: response.data.content._id
                });

                toast.success("Connecté !", {
                    position: toast.POSITION.BOTTOM_RIGHT
                })

                // Go to homepage
                this.props.navigate('/profil/')
            } else {
                this.setState({
                    errorLogin : response.data.message
                });
            }
        }).catch((error) => {
            this.setState({
                errorLogin : 'Erreur : Votre e-mail et/ou votre mot de passe est invalide.'
            });
        })
    }

    render() {
        return (
            
            <div className='login-form'>
                <MetaTags>
                    <title>Connexion à SoluSpots | Gérez vos annonces et véhicules</title>
                    <meta name="description" content="Connectez-vous à votre espace membre SoluSpots pour gérer vos annonces de véhicules, suivre vos réservations, et interagir avec les transporteurs et bétonniers. Simplifiez vos opérations en ligne."/>
                </MetaTags>
                <Link to={'/'} className='text-decoration-none d-xl-none d-lg-none d-md-none'>← Revenir à l'accueil</Link>
                <h1 className='login-title'>Connexion</h1>
                <Formik initialValues={{ email: '', password: '' }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email('L\'e-mail est invalide.')
                                .required('L\'e-mail est requis.'),
                            password: Yup.string()
                                .required('Le mot de passe est requis.'),
                        })}
                        onSubmit={(values, action) => {this.submitForm(values, action)}}
                >
                    {
                        ({ errors, status, touched, setFieldValue }) => {
                            return <Form>
                                {this.state.errorLogin && <div className="text-danger"><small>{this.state.errorLogin}</small></div>}
                                <div className="form-group mb-3">
                                    <Field id="email" name="email" type="text" placeholder="Adresse mail" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </div>
                                <div className={'form-group input-password mb-2' + (errors.password && touched.password ? ' fix-eye' : '')}>
                                    <Field id="password" name="password" type={this.state.showPassword ? 'text' : 'password'} placeholder="Mot de passe" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                    <button className='button-hide-password' type="button" onClick={() => this.setState({ showPassword: !this.state.showPassword})}>
                                        {this.state.showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                    </button>
                                    <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                </div>
                                <small><Link className="login-form-lost-password" to="/mot-de-passe-oublie/">Mot de passe oublié&nbsp;?</Link></small>
                                <div className="form-group mt-3">
                                    <button type="submit" className="btn btn-primary w-100">Connexion</button>
                                </div>

                                {Capacitor.getPlatform() !== 'ios' ?
                                    <div className="form-group mt-3 bloc-inscription">
                                        <h3>Vous n'avez pas de compte&nbsp;?</h3>
                                        <Link className="btn Ssbtn-outline-primary w-100" to="/inscription">
                                            Inscrivez-vous maintenant
                                        </Link>
                                    </div>
                                : ''}
                            </Form>
                        }
                    }
                </Formik>
            </div>
        );
    }
}

export default LoginForm;
