import React, { useState } from "react";
import { Col, Collapse, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import ContactForm from "../components/forms/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Capacitor } from "@capacitor/core";
import MetaTags from 'react-meta-tags';

function OffersPage() {
    const [searchTerm, setSearchTerm] = useState('');
    const [questionsCollapse, setQuestionsCollapse] = useState([false, false, false, false, false]); // Etat initial des collapse

    const questions = [
        { question: 'SoluSpots, c’est quoi ?', answer: 'SoluSpots est une plateforme innovante qui <strong>simplifie la location de véhicules</strong> et optimise la <strong>gestion de chantiers</strong> dans le secteur du béton. Nous proposons un large choix de <strong>camions malaxeurs</strong> et de <strong>pompes à béton</strong> disponibles à la location, adaptés aux besoins spécifiques de chaque chantier. </br> En facilitant la mise en relation entre les différents acteurs de l\'industrie du béton, SoluSpots permet d\'accélérer les échanges et d\'améliorer la coordination des opérations. Que vous soyez un bétonnier, un transporteur ou un entrepreneur, notre solution vous aide à rendre vos projets plus efficaces et fluides.'},
        { question: 'Comment se connecter sur SoluSpots ?', answer: 'Pour vous connecter sur soluspots.fr, suivez ces étapes simples après avoir confirmé votre inscription :<ul><li>Rendez-vous sur la page d’accueil du site.</li><li>Entrez votre identifiant et votre mot de passe.</li><li>Cliquez sur le bouton Connexion.</li></ul>Astuce : Veillez à ne laisser aucun espace vide lors de l\'inscription ou lorsque vous copiez votre identifiant et votre mot de passe.</br>Si vous venez de vous inscrire et que vous n’avez pas encore reçu d’email de confirmation, vous ne pourrez pas accéder à votre compte. Assurez-vous donc de vérifier votre boîte de réception et vos courriers indésirables.'},
        { question: 'Comment modifier les informations de mon compte ?', answer: ' Connectez-vous et rendez-vous sur votre page "Mon compte" : vous aurez alors la possibilité de mettre à jour les informations communiquées lors de votre inscription.'},
        { question: 'Quels véhicules peuvent être mis en ligne sur SoluSpots ?', answer: 'Les différents types de camion liés au transporteur de béton :<ul><li>les camions malaxeurs,</li><li>les malaxeurs avec tapis,</li><li>les mixopompes,</li><li>les malaxeurs semi-remorques,</li><li>les camions écologiques (électrique/biocarburant/gaz).</li></ul>'},
        { question: 'Puis-je mettre plusieurs véhicules en ligne ?', answer: 'SoluSpots propose plusieurs niveaux d’abonnement pour répondre au mieux à vos besoins.<br/>Si vous souhaitez louer plusieurs véhicules, les formules premium et VIP sont faites pour vous ! <a href="/transporteur">Découvrez les ici !</a>'}
    ];

    // Filtre selon recherche de mots clés
    const filteredData = questions.filter(item =>
        item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Collapse ou non une question
    const toggleQuestion = (index) => {
        const updatedCollapseState = [...questionsCollapse];
        updatedCollapseState[index] = !updatedCollapseState[index];
        setQuestionsCollapse(updatedCollapseState);
    };

    return (
        <>
            <MetaTags>
                <title>Contactez SoluSpots | Assistance pour transporteurs et bétonniers</title>
                <meta name="description" content="Vous avez des questions sur SoluSpots ? Contactez notre équipe pour obtenir de l'aide sur la gestion de votre compte, vos abonnements ou la mise en ligne de vos annonces. Nous sommes à votre service."/>
            </MetaTags>

            <div className="container">
                <h1 className="contact-title">
                    Bienvenue sur la plateforme SoluSpots <br />
                    <span className="contact-title-colored">Nous répondons à toutes vos questions</span>
                </h1>

                <input className="contact-question-faq" type="text" placeholder="Que recherchez-vous ?" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />

                {filteredData.map((item, index) => (
                    <div key={index}>
                        <h2 onClick={() => toggleQuestion(index)} className='contact-question-h2 product-h2-icon'>
                            {item.question} <span>{questionsCollapse[index] ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
                        </h2>
                        <Collapse in={questionsCollapse[index]}>
                        <span dangerouslySetInnerHTML={{ __html: item.answer }} />
                        </Collapse>
                    </div>
                ))}
            </div>

            <div className="bigcontainer">
                <div className="contact-section-form">
                    <Container>
                        <Row className="g-4">
                            <Col md={12} lg={6} xl={{'span':5, 'offset':1}}>
                                <div className="contact-section-form-content">

                                <h2 className="contact-section-form-content-title">
                                    Vous avez une question&nbsp;?<br />
                                        <span className="contact-section-form-content-title-colored">Écrivez-nous !</span>
                                    </h2>
                                    <div className="contact-section-form-content-block">
                                        <p className="contact-section-form-content-block-subtitle">
                                            Ou appelez-nous au 03 44 71 43 84
                                        </p>
                                        <p className="contact-section-form-content-block-text">
                                            En semaine de 9h à 18h - Appel gratuit
                                        </p>
                                    </div>
                                    <div className="contact-section-form-content-block">
                                        <p className="contact-section-form-content-block-subtitle">
                                            N’hésitez pas à nous suivre<br />
                                            sur les réseaux
                                        </p>
                                        <p className="contact-section-form-content-block-text">
                                            <a href="https://www.facebook.com/profile.php?id=100092581599269" target="_blank" className="contact-section-form-content-block-text-social">
                                                <FontAwesomeIcon icon={faFacebookF} />
                                            </a>
                                            <a href="https://www.linkedin.com/company/soluspots/" target="_blank" className="contact-section-form-content-block-text-social">
                                                <FontAwesomeIcon icon={faLinkedinIn} />
                                            </a>
                                            <a href="https://www.youtube.com/@soluspots" target="_blank" className="contact-section-form-content-block-text-social">
                                                <FontAwesomeIcon icon={faYoutube} />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} lg={6} xl={5}>
                                <ContactForm></ContactForm>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default OffersPage;
