import React from "react";

import { Col, Row, Container } from 'react-bootstrap';
// import { Capacitor } from "@capacitor/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import MetaTags from 'react-meta-tags';

import chrono from '../images/Discover-SoluSpots/chrono.jpg';
import earth from '../images/Discover-SoluSpots/earth.jpg';
import euro from '../images/Discover-SoluSpots/euro.jpg';
import network from '../images/Discover-SoluSpots/network.jpg';
import target from '../images/Discover-SoluSpots/target.jpg';
import truck from '../images/discover/PICTO_OPTIMISATION_SOLUSPOTS.svg';
import light from '../images/Discover-SoluSpots/light.jpg';
import engage from '../images/Discover-SoluSpots/engage.jpg';
import coop from '../images/Discover-SoluSpots/coop.jpg';
import VisuelHomeTransporteur from '../images/home/VISUEL_HOME_SOLUSPOTS_TRANSPORTEUR_BETONNIER_VERIFIES.png';



function SoluSpotsPage() {
    return (
        <div className="row">
            <MetaTags>
                <title>SoluSpots : La Solution Logistique Innovante pour Transporteurs et Bétonniers du Secteur BTP</title>
                <meta name="description" content="Découvrez SoluSpots, l'entreprise novatrice qui connecte transporteurs et bétonniers pour optimiser la livraison de béton. Profitez d'une solution efficace, réactive et durable, tout en réduisant vos coûts logistiques. Rejoignez-nous dans la transformation du secteur du BTP !"/>
            </MetaTags>

            <div className="col-12 mt-5">

                <div className="container">
                <Row>
                    <Col xs={12} className='my-auto'>
                            <p>Notre Projet : SoluSpots</p>
                        <div className='text-center mt-5 mb-5'>

                            <h1 className='home-section-desc-content-title text-center'>Un acteur clé dans la transformation du secteur du BTP</h1>
                            <p className="home-section-desc-content-block-desc">SoluSpots est une entreprise novatrice dans le domaine de la logistique du béton. En mettant en relation directe les transporteurs et les bétonniers, elle révolutionne la manière dont les commandes de béton sont gérées.</p>
                        </div>
                    </Col>
                </Row>
            </div>


                <div className="home-section-video">
                    <Container>
                        <h2>Interview de <span>M.Guesnet</span></h2>
                        <iframe src="https://www.youtube.com/embed/nY8O7ywB6zs?rel=0" title="SoluSpots - La plateforme qui facilite la vie de chantier !" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </Container>
                </div>

                <div className="container">
                    <Row>
                        <Col xs={12} className='my-auto'>
                            <div className='text-center mt-5 mb-5'>
                                <h2 className='home-section-desc-content-title text-center'>Une réponse à un <span>besoin concret</span></h2>
                                <p className="home-section-desc-content-block-desc">Fondée par Nicolas Guesnet, un expert du transport avec plus de 20 ans d'expérience, SoluSpots est née d'un constat simple : l'optimisation de la planification des livraisons de béton est un enjeu majeur pour les professionnels du secteur.</p>
                                <p className="home-section-desc-content-block-desc">Les contraintes liées à la nature du produit (temps de prise limité, volumes importants), ainsi que la nécessité de répondre à des demandes souvent urgentes, rendaient indispensable la mise en place d'une solution plus efficace et flexible.</p>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='home-section-rental'>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6} xl={5} className='my-auto'>
                                <img src={VisuelHomeTransporteur} alt="Camion loueurs SoluSpot" />
                            </Col>
                            <Col xs={12} lg={6} xl={5} className='my-auto'>
                                <div className='home-section-rental-content'>
                                    <h2 className='home-section-rental-content-title'>Comment ça marche ?</h2>
                                    <p>La plateforme SoluSpots permet :</p>

                                    <div className='home-section-rental-content-block'>
                                        <p className="home-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} />Une mise en relation rapide et efficace entre transporteurs et bétonniers</p>
                                    </div>
                                    <div className='home-section-rental-content-block'>
                                        <p className="home-section-rental-content-block-subtitle"> <FontAwesomeIcon icon={faCheck} />Une optimisation des tournées</p>
                                        <p className="home-section-rental-content-block-text"> grâce à un algorithme intelligent qui prend en compte de nombreux critères (distance, disponibilité des véhicules, contraintes horaires, etc.). </p>
                                    </div>
                                    <div className='home-section-rental-content-block'>
                                        <p className="home-section-rental-content-block-subtitle"> <FontAwesomeIcon icon={faCheck} />Un gain de temps et d'argent</p>
                                        <p className="home-section-rental-content-block-text"> pour les deux parties, grâce à une meilleure gestion des ressources et à une réduction des coûts.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="bigcontainer">
                    <div className="home-section-steps">
                        <Container>
                            <h2>Les avantages de SoluSpots</h2>
                            <Row>
                                <Col xs={12} lg={8}>
                                    <p className='home-section-steps-text'>
                                        En choisissant SoluSpots, les professionnels du transport et du béton bénéficient de nombreux avantages :
                                    </p>
                                </Col>

                            </Row>
                            <Row className="mt-5 g-5 justify-content-center">
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={chrono} alt="chrono"/>
                                        <p className="home-section-steps-cards-title">Une meilleure réactivité face aux demandes de livraison.</p>
                                    </div>
                                </Col>
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={euro} alt="euro"/>
                                        <p className="home-section-steps-cards-title">Une réduction des coûts logistiques.</p>
                                    </div>
                                </Col>
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={truck} alt="truck"/>
                                        <p className="home-section-steps-cards-title">Une optimisation de l'utilisation des véhicules.</p>
                                    </div>
                                </Col>
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={earth} alt="earth"/>
                                        <p className="home-section-steps-cards-title">Une diminution de l'impact environnemental grâce à une meilleure planification des tournées.</p>
                                    </div>
                                </Col>
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={network} alt="network"/>
                                        <p className="home-section-steps-cards-title">Un accès à un réseau étendu de transporteurs et de bétonniers.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div className="bigcontainer">
                    <div className="home-section-steps">
                        <Container>
                            <h2>Nos valeurs</h2>
                            <Row>
                                <Col xs={12} lg={8}>
                                    <p className='home-section-steps-text'>
                                        SoluSpots, c'est simple et rapide :<br/>
                                        Vous êtes transporteur ? Louez votre véhicule en quelques clics.<br/>
                                        Vous êtes bétonnier ? Trouvez le transporteur idéal pour votre chantier.<br/>
                                        Le tout en 3 étapes clés pour une mise en relation efficace.<br/>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mt-5 g-5 justify-content-center">
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={target} alt="target"/>
                                        <p className="home-section-steps-cards-title">Précision</p>
                                        <p>Chaque livraison est une promesse tenue, réalisée avec la plus grande exactitude et dans les délais impartis.</p>
                                    </div>
                                </Col>
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={chrono} alt="chrono"/>
                                        <p className="home-section-steps-cards-title">Réactivité</p>
                                        <p>Nous nous adaptons rapidement aux besoins de nos clients et trouvons des solutions efficaces en toutes circonstances.</p>
                                    </div>
                                </Col>
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={light} alt="light"/>
                                        <p className="home-section-steps-cards-title">Innovation</p>
                                        <p>Nous sommes en constante recherche de nouvelles technologies et de méthodes pour optimiser nos process et réduire notre impact environnemental.</p>
                                    </div>
                                </Col>
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={coop} alt="coop"/>
                                        <p className="home-section-steps-cards-title">Collaboration</p>
                                        <p>Nous travaillons en collaboration avec nos clients et nos équipes pour construire des partenariats durables.</p>
                                    </div>
                                </Col>
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={engage} alt="engage"/>
                                        <p className="home-section-steps-cards-title">Engagement</p>
                                        <p>Nous sommes engagés à fournir un service de qualité supérieure et à contribuer à la réussite de nos clients.</p>
                                    </div>
                                </Col>
                                <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                    <div className="home-section-steps-cards">
                                        <img className="home-section-steps-cards-img" src={earth} alt="earth"/>
                                        <p className="home-section-steps-cards-title">Durabilité</p>
                                        <p>Nous agissons de manière responsable pour préserver l'environnement et les ressources naturelles.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SoluSpotsPage;
