import Banner from "../components/Banner";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import VisuelRentabiliserFlotte from "../images/discover/SOLUSPOTS_RENTABILISER_FLOTTE.png";
import VisuelRechercheAnnonce from "../images/discover/SOLUSPOTS_RECHERCHE_ANNONCES_CENTRALES.png";
import PictoOptimisation from "../images/discover/PICTO_OPTIMISATION_SOLUSPOTS.svg";
import PictoAssurance from "../images/discover/PICTO_ASSURANCE_SOLUSPOTS.svg";
import PictoAppliSimple from "../images/discover/PICTO_APPLI_SIMPLE_SOLUSPOTS.svg";
import VisuelContact from "../images/discover/SOLUSPOTS_TRANSPORTEUR_MESSAGERIE.png";
import VisuelProfil from "../images/discover/SOLUSPOTS_TRANSPORTEUR_PROFIL.png";
import VisuelGererAnnonce from "../images/discover/SOLUSPOTS_TRANSPORTEUR_TABLEAU_DE_BORD.png";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Capacitor } from "@capacitor/core";
import Prices from "../components/Prices";
import MetaTags from 'react-meta-tags';

function TransporterPage() {

    const titleBanner = "Vous avez des <span class='banner-title-colored'>camions</span> à mettre <span class='banner-title-colored'>à disposition ?</span> <br/> <span class='banner-title-colored'>Optimiser votre flotte !</span>";

    return (
        <>
            <MetaTags>
                <title>Transporteurs de béton | Maximisez la rentabilité de votre flotte avec SoluSpots</title>
                <meta name="description" content="Augmentez la rentabilité de vos camions disponibles en rejoignant SoluSpots. Publiez vos annonces, choisissez vos tarifs et trouvez des clients rapidement grâce à notre plateforme dédiée aux transporteurs de béton."/>
            </MetaTags>
            <Banner titleBanner={titleBanner} bigBanner={true}></Banner>

            <div className="mediumcontainer">
                <div className="discover-section-optimize">
                    <div className="discover-section-optimize-item">
                        <img className="discover-section-optimize-item-image" src={PictoOptimisation}
                             alt=""/>
                        <p className="discover-section-optimize-item-title"><span
                            className="discover-section-optimize-item-title-colored">Rentabilisez</span><br/> votre flotte&nbsp;de&nbsp;véhicules
                        </p>
                    </div>
                    <div className="discover-section-optimize-separator"/>
                    <div className="discover-section-optimize-item">
                        <img className="discover-section-optimize-item-image" src={PictoAssurance} alt=""/>
                        <p className="discover-section-optimize-item-title"><span
                            className="discover-section-optimize-item-title-colored">Louez </span><br/>en toute sécurité</p>
                    </div>
                    <div className="discover-section-optimize-separator"/>
                    <div className="discover-section-optimize-item">
                        <img className="discover-section-optimize-item-image" src={PictoAppliSimple}
                             alt=""/>
                        <p className="discover-section-optimize-item-title">Profitez d'une application<br/> <span
                            className="discover-section-optimize-item-title-colored">simple et intuitive</span></p>
                    </div>
                </div>
            </div>

            <div className="bigcontainer">
                <div className="discover-section-steps">
                    <Container>
                        <Row>
                            <div className="discover-section-steps-presentation">
                                <h2>Une solution <span>unique</span> pour gérer votre flotte de camions</h2>
                                <p>
                                SoluSpots est une <strong>plateforme dédiée aux transporteurs</strong>, simplifiant la gestion de la flotte et optimisant la rentabilité. <strong>Planifiez et centralisez vos besoins</strong> en transport de béton via une solution tout-en-un, sans passer par plusieurs outils.
                                </p>
                                {Capacitor.getPlatform() != 'ios' ?
                                    <a className="solu-btn solu-btn-primary mt-4" href="/inscription" bis_skin_checked="1">Je m'inscris</a>
                                : ''}
                            </div>
                        </Row>
                    </Container>
                </div>

                <div className="home-section-steps" style={{textAlign: 'center'}}>
                    <Container>
                        <h2><span>Piloter sa flotte</span> devient un jeu d'enfant</h2>
                        <Row className="mt-5 gx-5 justify-content-center">
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="discover-section-cards-second">
                                    <span className="discover-section-cards-second-number">01</span>
                                    <img className="discover-section-cards-second-img" src={VisuelProfil} />
                                    <p className="discover-section-steps-item-title">Créez un profil <br/> transporteur facilement
                                    </p>
                                    <p className="discover-section-steps-item-subtitle">
                                        Votre profil sera votre porte d’entrée pour toutes les fonctionnalités de notre plateforme.<br/>&nbsp;
                                    </p>
                                </div>
                            </Col>
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="discover-section-cards-second">
                                    <span className="discover-section-cards-second-number">02</span>
                                    <img className="discover-section-cards-second-img" src={VisuelGererAnnonce} />
                                    <p className="discover-section-steps-item-title">Gérez vos annonces depuis votre tableau de bord</p>
                                    <p className="discover-section-steps-item-subtitle">
                                        Publiez vos annonces de location de camion, mettez les à jour, suivez les demandes de location et les réservations en temps réel.
                                    </p>
                                </div>
                            </Col>
                            <Col xs={11} sm={{'span':8,'offset':0}} md={{'span':6,'offset':0}} lg={4}>
                                <div className="discover-section-cards-second">
                                    <span className="discover-section-cards-second-number">03</span>
                                    <img className="discover-section-cards-second-img" src={VisuelContact} />
                                    <p className="discover-section-steps-item-title">Louez vos camions simplement et rapidement</p>
                                    <p className="discover-section-steps-item-subtitle">
                                        Avec notre système de messagerie intégré, vous pouvez facilement gérer vos échanges et les demandes de location.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        {Capacitor.getPlatform() != 'ios' ?
                            <a className="solu-btn solu-btn-primary mt-4" href="/inscription" bis_skin_checked="1">Je m'inscris</a>
                        : ''}
                    </Container>
                </div>

                <div className="bigcontainer">
                    <div className="home-section-video">
                        <Container>
                            <h2><span>Comment</span> ça marche ?</h2>
                            <iframe src="https://www.youtube.com/embed/lJgDp2mPPF0?rel=0" title="SoluSpots - La plateforme qui facilite la vie de chantier !" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            {Capacitor.getPlatform() != 'ios' ?
                                <a className="solu-btn solu-btn-primary mt-4" href="/inscription" bis_skin_checked="1">Je m'inscris</a>
                            : ''}
                        </Container>
                    </div>
                </div>

                <div className="discover-section-rental">
                    <Container>
                        <Row>
                            <Col md={12} xl={6} className='align-self-end order-2 order-xl-1'>
                                <img src={VisuelRentabiliserFlotte} alt="Camion loueurs - SoluSpot"/>
                            </Col>
                            <Col md={12} xl={6} className='order-1 order-xl-2'>
                                <div className="discover-section-rental-content">
                                    <h2 className="discover-section-rental-content-title"><span className="discover-section-rental-content-title-colored">Rentabilisez</span> votre flotte de véhicules dès la première utilisation&nbsp;!</h2>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} />Rentabilisez immédiatement votre flotte de véhicules</p>
                                        <p className="discover-section-rental-content-block-text">Mettez en ligne vos <strong>camions disponibles</strong> sur SoluSpots en quelques clics.</p>
                                    </div>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} />Définissez vos tarifs</p>
                                        <p className="discover-section-rental-content-block-text">
                                        Choisissez et ajustez facilement le prix de location de vos camions selon vos préférences.
                                        </p>
                                    </div>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} />Gagnez du temps avec une interface intuitive</p>
                                        <p className="discover-section-rental-content-block-text">
                                        La plateforme SoluSpots vous permet de gérer vos locations rapidement et efficacement.
                                        </p>
                                    </div>
                                    <div className="discover-section-rental-content-block">
                                        <p className="discover-section-rental-content-block-subtitle"><FontAwesomeIcon icon={faCheck} />Gérez tout depuis votre smartphone</p>
                                        <p className="discover-section-rental-content-block-text">Avec l'application SoluSpots, pilotez vos annonces, gérez vos demandes de location, et communiquez avec vos clients, où que vous soyez.</p>
                                    </div>

                                    {Capacitor.getPlatform() === 'ios' ?
                                        <a className="solu-btn solu-btn-primary mt-4 mb-4" href="/connexion" bis_skin_checked="1">Me connecter</a>
                                        :
                                        <a className="solu-btn solu-btn-primary mt-4 mb-4" href="/inscription" bis_skin_checked="1">Je m'inscris</a>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container className="py-5">
                    <Row>
                        <Col md={12} xl={6}>
                            <div className="discover-section-rental-content">
                                <h2>Sur SoluSpots, trouvez<br/>également <span className="discover-section-rental-content-title-colored">les annonces des centrales ou des chantiers !</span></h2>
                                <p className="home-section-steps-text">Découvrez les annonces des centrales à la recherche de véhicules pour le transport de béton. Grâce à la plateforme <strong>soluspots.fr</strong>, vous pouvez facilement <strong>louer vos véhicules</strong> ou trouver les centrales ayant besoin de votre flotte.</p>
                                <p className="home-section-desc-content-block-text">Profitez d’une <strong>double opportunité</strong> pour rentabiliser votre flotte et optimiser l'utilisation de vos équipements. SoluSpots vous aide à <strong>maximiser vos chances de location</strong> tout en favorisant une gestion plus efficace de vos ressources.</p>
                                <a className="solu-btn solu-btn-primary mt-4" href="/resultat-chantier" bis_skin_checked="1">Je consulte les annonces</a>
                            </div>
                        </Col>
                        <Col md={12} xl={6} className='align-self-end order-2 order-xl-1'>
                            <img src={VisuelRechercheAnnonce} alt="Camion loueurs - SoluSpot"/>
                        </Col>
                    </Row>
                </Container>

                {Capacitor.getPlatform() != 'ios' ?
                    <div className="discover-section-price">
                        <Row>
                            <div className="discover-section-price-presentation">
                                <h2 className="discover-section-price-presentation-title">Nos tarifs</h2>
                                <p className="discover-section-price-presentation-subtitle">
                                    SoluSpots vous propose différents abonnements<br/>afin de répondre au mieux à vos besoins.
                                </p>
                            </div>
                        </Row>

                        <Prices />
                    </div>
                : ''}
            </div>
        </>
    );
}

export default TransporterPage;