import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import VisuelPlusPopulaire from "../images/discover/VISUEL_LE_PLUS_POPULAIRE.png";
import OffreSurMesure from "../images/OFFRE_SUR_MESURE.png";

function Prices() {
    return (
        <>
            <Container>
                <Row className="mt-5" style={{alignContent: "stretch"}}>
                    <Col xs={12} md={6} xl={4} className="my-4">
                        <div className="discover-section-price-cards">
                            <h2 className="discover-section-price-cards-title">Basic</h2>
                            <hr className="discover-section-price-cards-separator"/>
                            <p className="discover-section-price-cards-number"><span
                                className="discover-section-price-cards-number-special">3,99€ HT/jour*</span> <br/>*soit 119,70€ HT/Mois</p>
                            <ul className="discover-section-price-cards-list">
                                <li className="discover-section-price-cards-list-item">1 véhicule**</li>
                                <li className="discover-section-price-cards-list-item">Espace client personnalisé</li>
                                <li className="discover-section-price-cards-list-item">Service d'assistance</li>
                            </ul>
                            <div className="discover-section-price-cards-button">
                                <Link to="/inscription" className="solu-btn-outline">Je profite de cette offre&nbsp;!</Link>
                                <small>**simultanément en ligne</small>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={6} xl={4} className="my-4">
                        <div className="discover-section-price-cards">
                            <div className="discover-section-price-cards-shape">
                                <img src={VisuelPlusPopulaire} alt="" />
                                <span className="discover-section-price-cards-shape-title">Le plus populaire</span>
                            </div>
                            <h2 className="discover-section-price-cards-title discover-section-price-cards-title-popular">Premium</h2>
                            <hr className="discover-section-price-cards-separator"/>
                            <p className="discover-section-price-cards-number"><span
                                className="discover-section-price-cards-number-special">7,99€ HT/jour*</span> <br/>*soit 230,70€ HT/Mois</p>
                            <ul className="discover-section-price-cards-list">
                                <li className="discover-section-price-cards-list-item">3 véhicules**</li>
                                <li className="discover-section-price-cards-list-item">Espace client personnalisé</li>
                                <li className="discover-section-price-cards-list-item">Service d'assistance</li>
                                <li className="discover-section-price-cards-list-item">Pré-audit gratuit et tarifs préférentiels chez notre partenaire Spartes.</li>
                            </ul>
                            <div className="discover-section-price-cards-button">
                                <Link to="/inscription" className="solu-btn-outline">Je profite de cette offre&nbsp;!</Link>
                                <small>**simultanément en ligne</small>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={4} className="my-4">
                        <div className="discover-section-price-cards">
                            <h2 className="discover-section-price-cards-title">Exclusive</h2>
                            <hr className="discover-section-price-cards-separator"/>
                            <p className="discover-section-price-cards-number"><span
                                className="discover-section-price-cards-number-special">9,99€ HT/jour*</span> <br/>*soit 299,70€ HT/Mois</p>
                            <ul className="discover-section-price-cards-list">
                                <li className="discover-section-price-cards-list-item">6 véhicules**</li>
                                <li className="discover-section-price-cards-list-item">Espace client personnalisé</li>
                                <li className="discover-section-price-cards-list-item">Service d'assistance</li>
                                <li className="discover-section-price-cards-list-item">Pré-audit gratuit et tarifs préférentiels chez notre partenaire Spartes.</li>
                            </ul>
                            <div className="discover-section-price-cards-button">
                                <Link to="/inscription" className="solu-btn-outline">Je profite de cette offre&nbsp;!</Link>
                                <small>**simultanément en ligne</small>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="discover-section-rental offre-sur-mesure">
                <Container>
                    <Row>
                        <Col md={12} xl={6}>
                            <div className="discover-section-rental-content pt-5 pb-5">
                                <h2 className="discover-section-rental-content-title text-start">Vous avez besoin d'une<br/>offre <span className="discover-section-rental-content-title-colored">sur mesure ?</span></h2>
                                <p className="discover-section-price-presentation-subtitle text-start">
                                L’offre Basic, Premium ou Exclusive ne vous conviennent pas ? Vous avez d’autres besoins spécifiques ? Contactez-nous, nous vous proposerons une offre faite pour vous !
                                </p>
                                <div className="text-start">
                                    <a className="solu-btn solu-btn-primary mt-4 mb-4 text-start" href="/inscription" bis_skin_checked="1">J’ai besoin d’une offre sur-mesure</a>
                                </div>
                            </div>
                        </Col>
                        <Col md={12} xl={6} className="offre-sur-mesure-img" >
                            <img src={OffreSurMesure} alt="Camion loueurs - SoluSpot"/>
                        </Col>
                    </Row>
                </Container>
        </div>
    </>
    );
}

export default Prices;